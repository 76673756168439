import { Text, breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { WelcomePageProps } from 'types/PageProps';

type WhatToExpectCalloutProps = {
  resultList: WelcomePageProps['whatToExpect'];
};

const CalloutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #faf9fc;
  border-radius: 8px;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: start;

  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  align-items: start;
  width: 100%;
`;

export const WhatToExpectCallout = ({
  resultList,
}: WhatToExpectCalloutProps) => {
  return (
    <CalloutContainer>
      <Text weight="heavy" size={3}>
        {resultList.title}
      </Text>
      <ContentContainer>
        {resultList.items.map(({ title, image }, index) => (
          <ItemContainer key={`callout-item-${index}`}>
            <img src={image} alt="" />
            <Text>{title}</Text>
          </ItemContainer>
        ))}
      </ContentContainer>
    </CalloutContainer>
  );
};
