import { updateHeader } from '@soluto-private/mx-app-header';
import {
  AsurionLoadingSpinner,
  GlobalStyles,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { Key, storage } from '@soluto-private/mx-context';
import { useMxContext } from '@soluto-private/mx-context-react';
import { RemoteExpertChat } from '@streaming-advisor/chat';
import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAnalytics } from 'react-shisell';
import { RecoilRoot } from 'recoil';
import styled, { createGlobalStyle } from 'styled-components';
import { ScrollToTop } from './components/ScrollToTop';
import { environment } from './environments/environment';
import AppRoutes from './Routes';
import { getAsurionIdToken } from './utils/getAsurionIdToken';
import { initializeWixiSdk } from './utils/NetworkScan';

storage.set(Key.Client, 'att'); // not sure the right way to set...

const AppGlobalStyle = createGlobalStyle`
  /* ChatBubbleToggle-button */
  #twilio-webchat-widget-root > * {
    bottom: 90px;
    right: 10px;
  }

  [data-test~="root-container"]:only-child {
    position: relative;
    bottom: -90px;
    right: -10px;
  }

  ${breakpoints.md2} {
    #twilio-webchat-widget-root > *{
      bottom: 30px;
      right: 30px;
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  width: 100%;
  justify-content: center;
`;

const url = new URL(window.location.href);

export const App = () => {
  const { client = 'att' } = useMxContext();
  const analytics = useAnalytics();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
      },
    },
  });

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      subNavConfig: {
        showBackButton: false,
        showPartnerLogo: true,
      },
    });

    const initializeChat = async () => {
      let asurionIdToken;

      try {
        asurionIdToken = await getAsurionIdToken(url);
      } catch (e) {
        console.error('asurionIdToken error: ', e);
      } finally {
        RemoteExpertChat.init(
          environment.production,
          client,
          asurionIdToken,
          analytics
        )
          .then(() => RemoteExpertChat.hideMessaging())
          .catch((e) => console.error(e));
      }
    };

    initializeChat();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeWixiSdk();
  }, []);

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RecoilRoot>
        <ScrollToTop />
        <AppGlobalStyle />
        <GlobalStyles />
        <Suspense
          fallback={
            <LoaderContainer>
              <AsurionLoadingSpinner />
            </LoaderContainer>
          }
        >
          <AppRoutes />
        </Suspense>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
