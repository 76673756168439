import { RoutePath } from 'RoutePath';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { withClickAnalytics } from '@streaming-advisor/analytics';
import RouterSpeedTest from './RouterSpeedTest';
import { usePageState } from 'hooks/usePageState';
import { useRouterScan } from 'hooks/useRouterScan';
import { RouterSpeedCompleteProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';

const Button = withClickAnalytics(AsurionButton);

const RouterScanCompleted = () => {
  const { goTo } = useFlow();
  const { page } = usePageState();
  const { cancelScan } = useRouterScan();
  const { title, subtitle, backButton, retestButton, continueButton } =
    usePageContentData<RouterSpeedCompleteProps>(
      RoutePath.RouterSpeedCompleted
    );

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: () => goTo(RoutePath.TestComplete),
  });

  return (
    <Container
      steps={['completed']}
      footer={{
        onBack: () => goTo(RoutePath.TestComplete),
        backButtonText: backButton?.title,
        buttonRightGroup: (
          <>
            <Button
              data-testid="RouterScanCompleted_RetestBtn"
              onClick={() => {
                cancelScan();
                goTo(RoutePath.RouterSpeedProgress);
              }}
              variant="outline"
              color="secondary"
            >
              {retestButton.title}
            </Button>
            <Button
              data-testid="RouterScanCompleted_ContinueBtn"
              onClick={() => {
                goTo(RoutePath.OnlineActivitiesQ1);
              }}
              color="secondary"
            >
              {continueButton.title}
            </Button>
          </>
        ),
      }}
    >
      <RouterSpeedTest
        title={title}
        subtitle={subtitle}
        progress={100}
        downloadSpeed={page.routerState?.downloadSpeed || ''}
      />
    </Container>
  );
};

export default RouterScanCompleted;
