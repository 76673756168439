import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
  fontSizes,
  fontWeights,
  Tag,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { usePageState } from 'hooks/usePageState';

const Title = styled(Text)`
  &&& {
    line-height: 1.15;
    margin-bottom: 0.5rem;
    font-size: ${fontSizes[6]};
    font-weight: ${fontWeights.heavy};
  }
`;

const SubTitle = styled(Text)`
  &&& {
    margin: 0.5rem 0 1.75rem;
    line-height: 1.3;
    color: #333f48;
    font-size: ${fontSizes[4]};
  }
`;

const CustomTag = styled(Tag)`
  &&& {
    display: inline-block;
    padding: 0.1875rem 0.1875rem 0.06rem;
    color: inherit;
    font-weight: ${fontWeights.heavy};
    font-size: ${fontSizes[4]};
    line-height: 1;
  }
`;

export const MainTitle: FC<{ title: string; subtitle: ReactNode }> = ({
  title,
  subtitle,
}) => {
  const {
    page: { results },
  } = usePageState();

  return (
    <div>
      <Title data-testid="Results_Header" forwardedAs="h2">
        {title}
      </Title>
      <SubTitle data-testid="Results_Subheader" forwardedAs="h4">
        {subtitle}&nbsp;
        <CustomTag
          size="regular"
          text={results?.user_code ?? ''}
          type="light"
        />
      </SubTitle>
    </div>
  );
};
