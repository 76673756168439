import { ErrorPage } from '@soluto-private/mx-asurion-ui-react-v3';
import {
  WithAnalyticsProps,
  withViewAnalytics,
} from '@streaming-advisor/analytics';
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { useFlow } from './hooks/useFlow';
import { useSkipRouterTest } from './hooks/useSkipRouterTest';
import NetworkScan from './pages/NetworkScan';
import Profile from './pages/Profile';
import { Question1, Question2 } from './pages/Questionnaires';
import ResultsOverviewPage from './pages/ResultsOverview';
import RouterScanCompleted from './pages/RouterScanCompleted';
import RouterScanProgress from './pages/RouterScanProgress';
import TestComplete from './pages/TestComplete';
import Welcome from './pages/Welcome';
import WifiLoading from './pages/WifiLoading';
import ProtectedRoute from './ProtectedRoute';
import { RoutePath } from './RoutePath';
import { pageState } from './states/atoms';

const privateRoute = (child: ReactNode) => (
  <ProtectedRoute>{child}</ProtectedRoute>
);

type WithAnalyticsComponentType = React.ComponentType<WithAnalyticsProps>;

const PageWrapper = (
  Component: WithAnalyticsComponentType,
  pageName: string,
  isPrivate: boolean | undefined,
  extraData?: Record<string, unknown>
) => {
  const analyticsData = { pageName, ...extraData };

  return isPrivate ? (
    privateRoute(<Component analyticsData={analyticsData} />)
  ) : (
    <Component analyticsData={analyticsData} />
  );
};

interface Route {
  pageName: string;
  path: RoutePath;
  isPrivate?: boolean;
  Component: WithAnalyticsComponentType;
}

export const ROUTES: Route[] = [
  {
    pageName: 'WelcomePage',
    path: RoutePath.Welcome,
    Component: withViewAnalytics(Welcome),
  },
  {
    pageName: 'NetworkTestPage',
    path: RoutePath.NetworkTest,
    Component: withViewAnalytics(NetworkScan),
  },
  {
    pageName: 'TestCompletePage',
    path: RoutePath.TestComplete,
    Component: withViewAnalytics(TestComplete),
  },
  {
    pageName: 'RouterSpeedProgressPage',
    path: RoutePath.RouterSpeedProgress,
    Component: withViewAnalytics(RouterScanProgress),
  },
  {
    pageName: 'RouterSpeedCompletedPage',
    path: RoutePath.RouterSpeedCompleted,
    Component: withViewAnalytics(RouterScanCompleted),
  },
  {
    pageName: 'OnlineActivitiesQ1Page',
    path: RoutePath.OnlineActivitiesQ1,
    Component: withViewAnalytics(Question1),
  },
  {
    pageName: 'WifiQ2Page',
    path: RoutePath.WifiQ2,
    Component: withViewAnalytics(Question2),
  },
  {
    pageName: 'WifiLoadingPage',
    path: RoutePath.WifiLoading,
    Component: withViewAnalytics(WifiLoading),
  },
  {
    pageName: 'ResultsPage',
    path: RoutePath.Results,
    Component: withViewAnalytics(ResultsOverviewPage),
  },
  {
    pageName: 'ProfilePage',
    path: RoutePath.Profile,
    Component: withViewAnalytics(Profile),
    isPrivate: true,
  },
];

const AppRoutes = () => {
  const { goTo } = useFlow();
  const resetPageState = useResetRecoilState(pageState);

  // TODO: Remove feature flag check once A/B test is concluded.
  // Fetch feature flag here so that we can include it in Fullstory view analytics
  const skipRouterTest = useSkipRouterTest();

  return (
    <Routes>
      <Route
        path={RoutePath.Home}
        element={<Navigate to="/welcome" replace />}
      />
      {/* TODO: remove this component and error page wrapper in every component  */}
      <Route
        path="/404"
        element={
          <ErrorPage
            button={{
              onClick: () => {
                resetPageState();
                goTo(RoutePath.Welcome);
              },
            }}
          />
        }
      />
      {ROUTES.map(({ path, pageName, isPrivate, Component }) => (
        <Route
          key={path}
          path={path}
          element={PageWrapper(Component, pageName, isPrivate, {
            skipRouterTest,
          })}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
