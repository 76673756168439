import axios from 'axios';
import { FeatureFlagsApiResponse } from '../types/FeatureFlagsApiResponse';
import { APPCONFIG_CONFIGURATION_PROFILE_NAMES as CONFIG_NAMES } from './constants';

export class AppConfigApi {
  constructor(public rootUrl: string) {}

  async getFeatureFlag(
    featureKey: string,
    accessToken: string,
    context?: Record<string, unknown>
  ) {
    try {
      // Convert context to a proper query string format
      const contextQuery = context
        ? Object.entries(context)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
        : '';
      const response = await axios.get<{ data: FeatureFlagsApiResponse }>(
        `${this.rootUrl}/app-config/${CONFIG_NAMES.featureFlags}/${featureKey}?${contextQuery}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data?.data;
    } catch (error) {
      // return undefined if error or not found
      return undefined;
    }
  }
}
