import { FC } from 'react';
import styled from 'styled-components';
import RouterPhone from './RouterPhone';
import RouterTestResults from './RouterTestResults';
import { formatSpeed } from 'utils/NetworkScan';
import { Text, breakpoints, colors } from '@soluto-private/mx-asurion-ui-react';
import { usePageContentData } from 'hooks/usePageContentData';
import { RouterSpeedCompleteProps } from 'types/PageProps';
import { RoutePath } from 'RoutePath';

const MainContainer = styled.div<{ isSpeedTestDone: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 9.3125rem;
  gap: 2.625rem;

  ${({ isSpeedTestDone }) =>
    isSpeedTestDone &&
    `
      border: 1px solid black;
      border-radius: 0.3125rem;
      margin-top: 2rem;
      margin-bottom: 1.125rem;
      padding: 1.375rem 0;
      gap: 1.4375rem;
      box-sizing: border-box;

      ${breakpoints.md} {
        margin-top: 3rem
      }
    `}
`;

const ContentContainer = styled.div`
  width: 19.125rem;
  height: 13.125rem;
  position: relative;
`;

const LoadingBarContainer = styled.div`
  width: 18.75rem;
  height: 0.5rem;
  position: relative;
`;

const LoadingBarWhole = styled.div`
  height: 0.5rem;
  background: #e6e6eb;
  border-radius: 2.5rem;
`;

const LoadingBarProgress = styled.div`
  position: absolute;
  width: ${(props: { progress: number }) => props.progress}%;
  height: 0.5rem;
  top: 0px;
  background: #000000;
  border-radius: 2.5rem;
  transition: width 2s;
`;

type RouterSpeedTestContainerProps = {
  progress: number;
  downloadSpeed: string;
};

const RouterSpeedTestContainer: FC<RouterSpeedTestContainerProps> = ({
  progress,
  downloadSpeed,
}) => {
  const { routerTestComplete } = usePageContentData<RouterSpeedCompleteProps>(
    RoutePath.RouterSpeedCompleted
  );
  const { description: speedDescription } = routerTestComplete;

  const isSpeedTestDone = progress === 100;

  return (
    <>
      <MainContainer isSpeedTestDone={isSpeedTestDone}>
        {!isSpeedTestDone && (
          <ContentContainer>
            <RouterPhone />
          </ContentContainer>
        )}
        <LoadingBarContainer>
          <LoadingBarWhole />
          <LoadingBarProgress progress={progress} />
        </LoadingBarContainer>
        {isSpeedTestDone && (
          <RouterTestResults score={formatSpeed(downloadSpeed)} />
        )}
      </MainContainer>
      {isSpeedTestDone && (
        <Text size={1} color={colors.neutralDeeper}>
          {speedDescription}
        </Text>
      )}
    </>
  );
};

export default RouterSpeedTestContainer;
