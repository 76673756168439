import {
  ContentTypes,
  Item,
  SpeedCriterias,
  TestContent,
  TestContentSkeleton,
} from '../types';
import { getFactoryByType } from './factoriesByType';

type TestContentReturn = TestContent<
  Record<
    SpeedCriterias.DOWNLOAD | SpeedCriterias.UPLOAD | SpeedCriterias.ROUTER,
    Item
  >
>;

export const createTestContent = (
  data: TestContentSkeleton
): { [key: string]: TestContentReturn } => {
  const { codeId, type, description, image, speedCriteria } = data.fields;

  return {
    [codeId]: {
      type: type || '',
      image: image?.fields?.file?.url || '',
      speedCriteria: Object.assign(
        {},
        ...(speedCriteria
          ? speedCriteria.map((item) =>
              getFactoryByType(ContentTypes.ITEM)(item)
            )
          : [])
      ),
      description: description || '',
    },
  };
};
