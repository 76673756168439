import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SingleSpaContext } from 'single-spa-react';
import App from './App';

export const Root = () => {
  const oneServiceContext = useContext(SingleSpaContext);

  return (
    <BrowserRouter basename={oneServiceContext.routePrefix.prefix}>
      <App />
    </BrowserRouter>
  );
};

export default Root;
