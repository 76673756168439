import React, { FC } from 'react';
import { UnorderedList, Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { usePageState } from 'hooks/usePageState';
import { ResultsPageProps } from 'types/PageProps';

const List = styled(UnorderedList)`
  &&& {
    padding-top: 0.67rem;

    ul,
    li:last-child {
      padding: 0;
    }
  }
`;

const PERCENTAGE_THRESHOLD = 0.2 as const;

const useGetImpactList = (
  items: ResultsPageProps['thingsThatImpactSpeed']['items']
) => {
  const {
    page: {
      networkState: { downloadSpeed },
      routerState,
    },
  } = usePageState();

  const trunc = (n: unknown): number => {
    if (typeof n === 'number') {
      return Math.trunc(n);
    }
    return 0;
  };

  const routerDownloadSpeed = trunc(+(routerState?.downloadSpeed ?? 0));
  const roomDownloadSpeed = trunc(downloadSpeed);

  const percentage =
    routerDownloadSpeed > 0 ? roomDownloadSpeed / routerDownloadSpeed : 0;
  const badResults = percentage <= PERCENTAGE_THRESHOLD;

  const results: Record<string, string> = {
    ageOfRouter: 'Age of router',
    sizeOfHome: 'Size of home',
    obstaclesRouterDevice: 'Obstacles between router and device',
    distanceFromRouter: 'Distance from router',
    numOfPeopleUsingNetwork: 'Number of people using the network',
    typeOfActivities: 'Type of activities and devices online',
    internetPlanAndType: 'Internet plan and type of internet',
  };

  items.forEach((item) => {
    if (results?.[item.value]) {
      results[item.value] = item.label;
    }
  });

  const getResults = (keys: string[]) => keys.map((key) => results[key]);

  return badResults
    ? getResults([
        'distanceFromRouter',
        'ageOfRouter',
        'sizeOfHome',
        'obstaclesRouterDevice',
      ])
    : getResults([
        'ageOfRouter',
        'numOfPeopleUsingNetwork',
        'typeOfActivities',
        'internetPlanAndType',
      ]);
};

type Props = {
  thingsThatImpactSpeed: ResultsPageProps['thingsThatImpactSpeed'];
};

export const ImpactList: FC<Props> = ({ thingsThatImpactSpeed }) => {
  const items = useGetImpactList(thingsThatImpactSpeed.items);
  return (
    <div>
      <Text size={3} weight="heavy">
        {thingsThatImpactSpeed.title}
      </Text>
      <List hasIcons isSpacingCompact>
        {items.map((item, index) => (
          <UnorderedList.ListItem
            key={`support-list-item-${index}`}
            iconColor="#EDB800"
            iconSrc="SystemAlertWarning"
          >
            <React.Fragment key={`support-list-item-${index}.0`}>
              {item}
            </React.Fragment>
          </UnorderedList.ListItem>
        ))}
      </List>
    </div>
  );
};
