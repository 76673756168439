import { Item, ItemSkeleton } from '../types';
import { convertToReactComponent } from '../utils';

export const createItem = (
  data: ItemSkeleton,
  isNested = true
): { [key: string]: Item } | Item => {
  const { codeId, title, subtitle, label, value, image, iconName } =
    data.fields;

  const item: Item = {
    title: convertToReactComponent(title) || null,
    subtitle: convertToReactComponent(subtitle) || null,
    label: label || '',
    value: value || '',
    image: (image?.fields?.file?.url as string) || '',
    iconName: iconName || '',
  };
  return isNested ? { [codeId]: item } : item;
};
