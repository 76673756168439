const calculateHealthScore = (downloadSpeed: number): number => {
  const healthScores = [
    [35000, 5],
    [20000, 4],
    [10000, 3],
    [5000, 2],
    [3000, 1],
  ];

  for (const healthScore of healthScores) {
    if (downloadSpeed > healthScore[0]) {
      return healthScore[1];
    }
  }

  return 0;
};

export const calculateCapabilities = (
  downloadSpeed: number
): Record<string, boolean> => {
  const healthScore = calculateHealthScore(downloadSpeed * 1000); // format to kbps

  const capabilities = {
    uhdVideoStreaming: false,
    videoStreaming: false,
    audioStreaming: false,
    gaming: false,
    webBrowsing: false,
    videoChat: false,
  };

  if (healthScore > 4) {
    capabilities.uhdVideoStreaming = true;
  }

  if (healthScore > 3) {
    capabilities.videoStreaming = true;
  }

  if (healthScore > 2) {
    capabilities.videoChat = true;
  }

  if (healthScore > 1) {
    capabilities.gaming = true;
    capabilities.audioStreaming = true;
  }

  if (healthScore > 0) {
    capabilities.webBrowsing = true;
  }

  return capabilities;
};
