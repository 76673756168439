import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  getFontSize,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const QuestionTitle = styled(Text)`
  && {
    font-size: ${getFontSize(5)};

    ${breakpoints.md2} {
      font-size: ${getFontSize(6)};
    }
  }
`;

const QuestionSubtitle = styled(Text)`
  && {
    font-size: ${getFontSize(3)};

    ${breakpoints.md2} {
      font-size: ${getFontSize(4)};
    }
  }
`;

type QuestionTextWrapper = {
  tag: string;
  question: 1 | 2;
  title: string;
  subtitle: ReactNode | string;
  titleId?: string;
  subtitleId?: string;
};

const QuestionTextWrapper: FC<QuestionTextWrapper> = ({
  tag = 'Question',
  question,
  title,
  subtitle,
  titleId,
  subtitleId,
}) => (
  <TextWrapper>
    <Text size={2} weight="heavy">
      {tag} {question}/2
    </Text>
    <QuestionTitle forwardedAs="h1" weight="heavy" data-testid={titleId}>
      {title}
    </QuestionTitle>
    <QuestionSubtitle forwardedAs="h2" data-testid={subtitleId}>
      {subtitle}
    </QuestionSubtitle>
  </TextWrapper>
);

export default QuestionTextWrapper;
