import { atom } from 'recoil';
import { sessionStorageEffect } from '../effects/sessionStorageEffect';
import {
  OnlineActivityValues,
  SlowWifiValues,
} from '@streaming-advisor/questions';
import { GeoIpIspInfo } from '@soluto-private/wixi-web-sdk';
import { ResultsApiResponse } from '@streaming-advisor/results';

export type NetworkPageState = {
  downloadSpeed: string;
  uploadSpeed: string;
  geoIpIspInfo?: GeoIpIspInfo;
};

export type RouterPageState = {
  downloadSpeed: string;
};

export type QuestionPageState = {
  q1: OnlineActivityValues[];
  q2: SlowWifiValues[];
};

export type PageState = {
  networkState: NetworkPageState;
  routerState: RouterPageState | null;
  questionState: QuestionPageState;
  results?: ResultsApiResponse;
};

export const pageState = atom<PageState>({
  key: 'pageState',
  default: {
    networkState: {
      downloadSpeed: '',
      uploadSpeed: '',
    },
    routerState: null,
    questionState: {
      q1: [],
      q2: [],
    },
  },
  effects: [sessionStorageEffect('pageState')],
});
