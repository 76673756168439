import { atom } from 'recoil';
import { sessionStorageEffect } from '../effects/sessionStorageEffect';

export type ConfigState = {
  featureOverrides: {
    skipRouterTest: boolean | null;
  };
};

export const configState = atom<ConfigState>({
  key: 'configState',
  default: {
    featureOverrides: {
      skipRouterTest: null,
    },
  },
  effects: [sessionStorageEffect('configState')],
});
