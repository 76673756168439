import { useQuery } from 'react-query';

import { useMxContext } from '@soluto-private/mx-context-react';

import { fetchFromContentful } from '../ContentfulClient';
import { ContentTypes, PageSkeleton } from '../types';
import { createPage } from '../factories';
import { validateLanguage } from '../utils';

export const usePageData = <T>(
  environment: string,
  routeId: string,
  isPreview?: boolean
): T => {
  const { client = 'att', language } = useMxContext();

  const { data } = useQuery(
    [
      environment, /// Contentful environment
      'contentful',
      {
        content_type: ContentTypes.PAGE,
        'fields.routeId[in]': routeId,
        'fields.partner[in]': `all,${client}`,
        limit: 1,
        locale: validateLanguage(language),
        include: 3,
      },
      isPreview,
    ],
    fetchFromContentful<PageSkeleton>,
    { staleTime: Infinity }
  );

  return {
    ...createPage<T>({
      ...data?.items[0],
      contentTypeId: ContentTypes.PAGE,
    } as PageSkeleton),
  };
};
