import { updateHeader, BackButtonConfig } from '@soluto-private/mx-app-header';
import { ReactNode, useEffect } from 'react';

const getBackTitle = (backTitle?: UseUpdateMxHeader['backTitle']) => {
  try {
    const s = (backTitle as string[])?.[0];
    return s;
  } catch (error) {
    return undefined;
  }
};

type UseUpdateMxHeader = {
  onBack?: BackButtonConfig['onBack'];
  backTitle?: ReactNode;
};

export const useUpdateMxHeader = (headerConfig?: UseUpdateMxHeader) => {
  useEffect(() => {
    updateHeader({
      // onBack and backTitle were decprecated. button property moved to subNavConfig.backButtonConfig
      view: 'service-view',
      menu: true,
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
        backButtonConfig: {
          /// typecast to string | undefined
          title: getBackTitle(headerConfig?.backTitle),
          onBack: headerConfig?.onBack,
        },
      },
    });
  }, [headerConfig]);
};
