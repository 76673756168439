import React, { useEffect } from 'react';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { Container } from 'components/Main';
import { NetworkScanInProgress } from 'pages/NetworkScan/components';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { useFlow } from 'hooks/useFlow';
import { RoutePath } from 'RoutePath';
import { withClickAnalytics } from '@streaming-advisor/analytics';
import { NetworkTestProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';

const Button = withClickAnalytics(AsurionButton);

const NetworkScan = () => {
  const { scanState, cancelScan } = useNetworkScan();
  const { goTo } = useFlow();
  const { cancelButton, backButton } = usePageContentData<NetworkTestProps>(
    RoutePath.NetworkTest
  );

  const handleBack = () => {
    cancelScan();
    goTo(RoutePath.Welcome);
  };

  const ButtonRightGroup = (
    <Button
      data-testid="NetworkScan_CancelBtn"
      variant="outline"
      color="secondary"
      onClick={handleBack}
    >
      {cancelButton.title}
    </Button>
  );

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: handleBack,
  });

  const isScanCompleted = scanState === SpeedTestStatus.completed;

  useEffect(() => {
    if (isScanCompleted) {
      goTo(RoutePath.TestComplete);
    }
  }, [isScanCompleted, goTo]);

  return (
    <Container
      steps={['inprogress']}
      footer={{
        onBack: handleBack,
        buttonRightGroup: ButtonRightGroup,
        backButtonText: backButton?.title,
      }}
    >
      {!isScanCompleted ? <NetworkScanInProgress /> : null}
    </Container>
  );
};

export default NetworkScan;
