import { getAccessToken } from '@soluto-private/mx-app-authentication';
import {
  AppConfigApi,
  FeatureFlagsApiResponse,
} from '@streaming-advisor/app-config';
import { environment } from 'environments/environment';

const appConfigApi = new AppConfigApi(environment.appConfigApiEndpoint);

export const getFeatureFlag = (
  featureKey: string,
  context?: Record<string, unknown>
): Promise<FeatureFlagsApiResponse | undefined> => {
  const accessToken = getAccessToken() || '';
  return appConfigApi.getFeatureFlag(featureKey, accessToken, context);
};
