import { Text, breakpoints, colors } from '@soluto-private/mx-asurion-ui-react';
import { Welcome } from 'components/PageHeader/Welcome';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { usePageContentData } from 'hooks/usePageContentData';
import { FC } from 'react';
import { RoutePath } from 'RoutePath';
import styled from 'styled-components';
import { NetworkTestCompleteProps } from 'types/PageProps';
import { SpeedResult } from './SpeedResult';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpeedContainer = styled.div`
  gap: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  border-radius: 0.3125rem;
  padding: 1.5rem 0;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 1.125rem;

  ${breakpoints.md2} {
    margin-top: 3rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoints.md2} {
    padding: 0 1.5rem 0;
  }
`;

export const NetworkScanComplete: FC<{ skipRouterTest?: boolean }> = ({
  skipRouterTest,
}) => {
  const { downloadSpeedLabel, uploadSpeedLabel } = useNetworkScan();

  // TODO: Remove feature flag check once A/B test is concluded.
  // Also remove the contentful associated to the removed flow.
  const routeId = skipRouterTest
    ? `${RoutePath.TestComplete}-skiproutertest`
    : RoutePath.TestComplete;

  const { title, networkTestComplete } =
    usePageContentData<NetworkTestCompleteProps>(routeId);

  const { speedCriteria, description: speedDescription } = networkTestComplete;

  return (
    <Wrapper>
      <Welcome title={title} />
      <Container>
        <SpeedContainer>
          <SpeedResult
            title={speedCriteria.downloadSpeed.title}
            speedLabel={speedCriteria.downloadSpeed.label}
            speedValue={downloadSpeedLabel}
            isLoading={false}
            variant="download"
          />
          <SpeedResult
            title={speedCriteria.uploadSpeed.title}
            speedLabel={speedCriteria.uploadSpeed.label}
            speedValue={uploadSpeedLabel}
            isLoading={false}
            variant="upload"
          />
        </SpeedContainer>
        <Text size={1} color={colors.neutralDeeper}>
          {speedDescription}
        </Text>
      </Container>
    </Wrapper>
  );
};
