import { AssetFile, EntryFields } from 'contentful';
import { ReactNode } from 'react';

export enum ContentTypes {
  PAGE = 'page',
  EXPERT_PROGRESS = 'expertProgress',
  TEST_CONTENT = 'testContent',
  LIST = 'list',
  ITEM = 'item',
  FEEDBACK = 'feedbackForm',
}

interface DefaultDataQuery {
  'fields.partner[in]': string;
  limit: number;
  locale: string;
  include: number;
}
export interface PageDataQuery extends DefaultDataQuery {
  content_type: ContentTypes.PAGE;
  'fields.routeId[in]': string;
}
export interface ExpertProgressDataQuery extends DefaultDataQuery {
  content_type: ContentTypes.EXPERT_PROGRESS;
  'fields.codeId[in]': string;
}

export enum TestTypes {
  NETWORK = 'network',
  ROUTER = 'router',
}

export interface ListSkeleton {
  contentTypeId: ContentTypes.LIST;
  fields: {
    codeId: EntryFields.Text;
    title: EntryFields.Text;
    items: EntryFields.Array<EntryFields.EntryLink<ItemSkeleton>>;
  };
}
export interface List {
  title: string;
  items: Item[];
}
export interface TestContentSkeleton {
  contentTypeId: ContentTypes.TEST_CONTENT;
  fields: {
    codeId: EntryFields.Text;
    type: EntryFields.Text<TestTypes>;
    speedCriteria: EntryFields.Array<EntryFields.EntryLink<ItemSkeleton>>;
    image: EntryFields.AssetLink;
    description: EntryFields.Text;
  };
}

export enum SpeedCriterias {
  DOWNLOAD = 'downloadSpeed',
  UPLOAD = 'uploadSpeed',
  ROUTER = 'routerSpeed',
}
export interface TestContent<T> {
  type: TestTypes;
  speedCriteria: T;
  image: string | AssetFile;
  description: string;
}
export interface ItemSkeleton {
  contentTypeId: ContentTypes.ITEM;
  fields: {
    codeId: EntryFields.Text;
    title: EntryFields.RichText;
    subtitle: EntryFields.RichText;
    label: EntryFields.Text;
    value: EntryFields.Text;
    image: EntryFields.AssetLink;
    iconName: EntryFields.Text;
  };
}
export interface Item {
  title: ReactNode;
  subtitle: ReactNode;
  label: string;
  value: string;
  image: string;
  iconName: string;
}

export interface FeedbackSkeleton {
  contentTypeId: ContentTypes.FEEDBACK;
  fields: {
    entryName: EntryFields.Text;
    title: EntryFields.Text;
    header: EntryFields.Text;
    subheader: EntryFields.Text;
    feedbackChoices: EntryFields.Array<EntryFields.Text>;
    textHeader: EntryFields.Text;
    commentsPlaceholder: EntryFields.Text;
    commentsHelperText: EntryFields.Text;
    submitCta: EntryFields.Text;
    completeMessageText: EntryFields.Text;
  };
}

export interface Feedback {
  entryName: string;
  title: string;
  header: string;
  subheader: string;
  feedbackChoices: string[];
  textHeader: string;
  commentsPlaceholder: string;
  commentsHelperText: string;
  submitCta: string;
  completeMessageText: string;
}

export interface PageSkeleton {
  contentTypeId: ContentTypes.PAGE;
  fields: {
    title: EntryFields.Text;
    subtitle: EntryFields.RichText;
    tag: EntryFields.Text;
    content: EntryFields.Array<
      | EntryFields.EntryLink<ItemSkeleton>
      | EntryFields.EntryLink<TestContentSkeleton>
      | EntryFields.EntryLink<ListSkeleton>
    >;
    buttons: EntryFields.Array<EntryFields.EntryLink<ListSkeleton>>;
    positiveFeedbackForm: EntryFields.EntryLink<FeedbackSkeleton>;
    negativeFeedbackForm: EntryFields.EntryLink<FeedbackSkeleton>;
  };
}

export interface ExpertProgressSkeleton {
  contentTypeId: ContentTypes.EXPERT_PROGRESS;
  fields: {
    codeId: EntryFields.Text;
    title: EntryFields.RichText;
    subtitle: EntryFields.RichText;
    shiftSchedule: EntryFields.Text;
    steps: EntryFields.Array<EntryFields.Text>;
    image: EntryFields.AssetLink;
  };
}
export interface ExpertProgress {
  title: ReactNode;
  subtitle: ReactNode;
  shiftSchedule: string;
  steps: string[];
  image: string | AssetFile;
}
