import {
  Button,
  Link,
  Size,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { ResultsPageProps } from 'types/PageProps';
import {
  AnalyticClickTypes,
  withClickAnalytics,
} from '@streaming-advisor/analytics';
import { RemoteExpertChat } from '@streaming-advisor/chat';
import { useMediaQuery } from 'hooks/useMediaQuery';

type ContextExpertButtonsProps = {
  chatButton: ResultsPageProps['chatButton'];
  callButton: ResultsPageProps['callButton'];
};

const ContactExpertWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  width: 100%;
  gap: 0.75rem;

  ${breakpoints.md2} {
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  }
`;

const ChatExpertBtn = styled(withClickAnalytics(Button))`
  border-width: 1px;
  &&& {
    flex-basis: 100%;
  }
`;
const CallExpertBtn = styled(withClickAnalytics(Link))`
  border-width: 1px;
  &&& {
    flex-basis: 100%;
  }
`;

export const ContactExpertButtons = ({
  chatButton,
  callButton,
}: ContextExpertButtonsProps) => {
  const isMobileSize = useMediaQuery(`(max-width: ${Size.MD2}px)`);

  return (
    <ContactExpertWrapper>
      <ChatExpertBtn
        data-testid="Aside_ChatExpertBtn"
        color="secondary"
        iconSide="left"
        size={isMobileSize ? 'small' : 'medium'}
        iconSrc={isMobileSize ? null : chatButton.iconName}
        onClick={() => {
          RemoteExpertChat.openMessagingOverlay();
        }}
        variant="outline"
        analyticsData={{
          type: AnalyticClickTypes.EXPERT_CHAT_CLICKED,
        }}
      >
        {chatButton.title}
      </ChatExpertBtn>
      <CallExpertBtn
        data-testid="Aside_CallExpertBtn"
        variant="button"
        color="secondary"
        iconSide="left"
        href={`tel:${callButton.value}`}
        iconSrc={isMobileSize ? '' : callButton.iconName}
        btnVariantProps={{
          btnSize: isMobileSize ? 'small' : 'medium',
          btnVariant: 'default',
        }}
        analyticsData={{
          type: AnalyticClickTypes.EXPERT_CALL_CLICKED,
        }}
      >
        {callButton.title}
      </CallExpertBtn>
    </ContactExpertWrapper>
  );
};
