import axios, { AxiosError } from 'axios';
import { ResultsApiResponse } from '../types/ResultsApiResponse';
import { SendResultsApiRequest } from '../types/SendResultsApiRequest';
import { ApiError } from '../types/ApiError';

export class ResultsApi {
  constructor(public rootUrl: string) {}

  async sendResults(data: SendResultsApiRequest, accessToken: string) {
    const res = await axios.post<{ data: ResultsApiResponse }>(
      `${this.rootUrl}/results`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        timeout: 20000,
      }
    );
    return res.data?.data;
  }

  async getResults(code: string, accessToken: string) {
    try {
      const response = await axios.get<{ data: ResultsApiResponse }>(
        `${this.rootUrl}/results/${code}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        throw new ApiError('code.not.found');
      }

      throw error;
    }
  }
}
