import styled from 'styled-components';
import {
  UnorderedList,
  Text,
  colors,
  AsurionLoadingSpinner,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { usePageState } from 'hooks/usePageState';
import { ResultsPageProps } from 'types/PageProps';
import { FC } from 'react';
import { calculateCapabilities } from 'utils/calculateCapabilities';
import { formatSpeed } from 'utils/NetworkScan';

const List = styled(UnorderedList)`
  &&& {
    padding-top: 0.67rem;

    ul,
    li:last-child {
      padding: 0;
    }
  }
`;

const Spinner = styled(AsurionLoadingSpinner)`
  margin-top: 0.67rem;
`;

const Dash = styled(Text)`
  display: block;
`;

const capabilitiesMap: Record<string, string> = {
  uhdVideoStreaming: '4k streaming',
  videoStreaming: 'Streaming on multiple devices',
  audioStreaming: 'Audio streaming',
  gaming: 'Gaming',
  webBrowsing: 'Uploading and downloading large files',
  videoChat: 'Video calling',
};

type SupportListProps = {
  networkSupportList: ResultsPageProps['networkSupportList'];
};

export const SupportList: FC<SupportListProps> = ({ networkSupportList }) => {
  const { page } = usePageState();
  const { downloadSpeed } = page.networkState;
  const capabilities = calculateCapabilities(formatSpeed(downloadSpeed));

  networkSupportList.items.forEach((item) => {
    if (capabilitiesMap[item.value]) {
      capabilitiesMap[item.value] = item.label;
    }
  });

  return (
    <div>
      <Text size={3} weight="heavy">
        {networkSupportList.title}
      </Text>
      {capabilities ? (
        <List hasIcons isSpacingCompact>
          {Object.keys(capabilitiesMap).map((capability) => {
            const isSupported = capabilities[capability];
            const testid = isSupported ? 'Supported' : 'NotSupported';
            return (
              <UnorderedList.ListItem
                data-testid={`Results_Capabilities_${testid}`}
                key={`support-list-${capability}`}
                iconColor={
                  isSupported ? colors.brandGreenDeep : colors.errorDeep
                }
                iconSrc={
                  isSupported ? 'SystemAlertCheckmark' : 'SystemAlertXNo'
                }
              >
                <Text>{capabilitiesMap[capability]}</Text>
              </UnorderedList.ListItem>
            );
          })}
        </List>
      ) : downloadSpeed ? (
        <Spinner />
      ) : (
        <Dash size={5}>-</Dash>
      )}
    </div>
  );
};
