import React, { FC } from 'react';
import styled from 'styled-components';
import { FlexTemplate } from '@soluto-private/mx-asurion-ui-react-v3';
import { GettingStarted, GettingStartedProps } from 'components/Aside';
import { Footer, FooterProps } from './Footer';

const PlainTemplate = styled(FlexTemplate)`
  &&& {
    grid-template-areas:
      'main-content main-content'
      'button-group button-group';
  }
`;

const MainContentContainer = styled(FlexTemplate.MainContentContainer)`
  &&& {
    overflow-y: auto;
  }
`;

const SidePanelContainer = styled(FlexTemplate.SidePanelContainer)`
  &&& {
    overflow-y: auto;
    padding: 0;
  }
`;

type ContainerProps = {
  children?: React.ReactNode;
  footer?: FooterProps;
  showSidebar?: boolean;
} & GettingStartedProps;

export const Container: FC<ContainerProps> = ({
  children,
  footer,
  steps,
  showSidebar = true,
}) => {
  const headerHeight = '163px';

  const Template = showSidebar ? FlexTemplate : PlainTemplate;

  return (
    <Template upperVerticalOffset={headerHeight}>
      <MainContentContainer>{children}</MainContentContainer>
      {showSidebar ? (
        <SidePanelContainer>
          <GettingStarted steps={steps} />
        </SidePanelContainer>
      ) : null}
      {footer?.buttonRightGroup ? (
        <FlexTemplate.ButtonGroup
          alignment={footer.backButtonText ? 'start' : 'end'}
        >
          <Footer {...footer} />
        </FlexTemplate.ButtonGroup>
      ) : null}
    </Template>
  );
};
