/**
 * All the configurations for BE and FE are stored in one app.
 * */
export const APPCONFIG_APPLICATION_NAME = 'streaming-advisor';

/**
 * Mapping of configuration profiles. They can be from different sources.
 * For example Secrets Manager or just from App Config itself
 */
export const APPCONFIG_CONFIGURATION_PROFILE_NAMES = {
  // A basic collection of simple feature flags.
  featureFlags: 'feature-flags',
  // We only need one for now. We can add more in the future as needed.
} as const;
