import { useRecoilState } from 'recoil';
import { ConfigState, configState } from 'states/atoms';

type UseConfigStateResponse = {
  config: ConfigState;
  setSkipRouterTest: (skipRouterTest: boolean) => void;
};

export const useConfigState = (): UseConfigStateResponse => {
  const [config, setConfig] = useRecoilState(configState);

  const setSkipRouterTest = (skipRouterTest: boolean) => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      featureOverrides: {
        ...oldConfig.featureOverrides,
        skipRouterTest,
      },
    }));
  };

  return {
    config,
    setSkipRouterTest,
  };
};
