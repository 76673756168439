import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Size,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { useMediaQuery } from 'hooks/useMediaQuery';

export type FooterProps = {
  onBack?: () => void;
  backButtonText?: ReactNode;
  buttonRightGroup?: ReactNode;
};

const FooterDesktop = (footer: FooterProps) => {
  return (
    <>
      {footer.backButtonText ? (
        <Button
          onClick={() => footer.onBack?.()}
          color="secondary"
          iconSrc="ArrowLeft"
          variant="flat"
          iconSide="left"
        >
          {footer.backButtonText}
        </Button>
      ) : null}
      <ButtonGroup>{footer.buttonRightGroup}</ButtonGroup>
    </>
  );
};

const FullWidthButtonGroup = styled(ButtonGroup)`
  && {
    width: 100%;
  }
`;

const FooterMobile = (footer: FooterProps) => {
  return (
    <FullWidthButtonGroup hasFullWidthButtons={true}>
      {footer.buttonRightGroup}
    </FullWidthButtonGroup>
  );
};

export const Footer = (footer: FooterProps) => {
  const isMobile = useMediaQuery(`(max-width: ${Size.MD}px)`);

  return isMobile ? (
    <FooterMobile {...footer} />
  ) : (
    <FooterDesktop {...footer} />
  );
};
