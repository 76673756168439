import { useQuery } from 'react-query';
import { getFeatureFlag } from 'utils/getFeatureFlag';

export const useGetFeatureFlag = (
  featureKey: string,
  context?: Record<string, unknown>
) => {
  const { data, isLoading } = useQuery(
    ['getFeatureFlag', featureKey, context],
    async () => await getFeatureFlag(featureKey, context),
    { staleTime: 3600000 } // cache up to 1 hour
  );
  // If query client suspense is enabled, no need to check isLoading.
  return { feature: data, isFeatureLoading: isLoading };
};
