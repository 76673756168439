import axios from 'axios';
import qs from 'qs';
import { environment } from 'environments/environment';

export const getAsurionIdToken = async (url: URL) => {
  const authCode = url.searchParams.get('code');
  if (!authCode) return;

  const EXCLUDED_SEARCH_PARAMS = ['code', 'state', 'scope'];

  EXCLUDED_SEARCH_PARAMS.forEach((param) => {
    url.searchParams.delete(param);
  });

  const data = {
    code: authCode,
    client_id: environment.asurionIdConfig.clientId,
    grant_type: 'authorization_code',
    redirect_uri: url.toString(),
  };

  const endpoint = `${environment.asurionIdConfig.apiDomain}/oauth2/token`;

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: endpoint,
  };

  const response = await axios(options);

  return response.data?.access_token;
};
