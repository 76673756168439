import { FeedbackSkeleton, PageSkeleton } from '../types';
import { convertToReactComponent } from '../utils';
import { getFactoryByType } from './factoriesByType';
import { EntryFields } from 'contentful';

const formatFeedback = <T>(
  key: keyof T,
  feedbackForm: EntryFields.EntryLink<FeedbackSkeleton>
) => {
  if (feedbackForm) {
    return {
      [key]: getFactoryByType(feedbackForm.sys.contentType.sys.id)(
        feedbackForm
      ),
    };
  }

  return {};
};

export const createPage = <T>(data: PageSkeleton): T => {
  if (!data) {
    // TODO: Maybe add some error throw here with the use of Error Boundary
    console.error('No page entry found');
  }

  const {
    title,
    subtitle,
    content,
    buttons,
    tag,
    positiveFeedbackForm,
    negativeFeedbackForm,
  } = data.fields;

  return {
    title: title || '',
    subtitle: convertToReactComponent(subtitle) || null,
    tag: tag || '',
    ...(content
      ? Object.assign(
          {},
          ...content.map((item) =>
            getFactoryByType(item.sys.contentType.sys.id)(item)
          )
        )
      : {}),
    ...(buttons
      ? Object.assign(
          {},
          ...buttons.map((item) =>
            getFactoryByType(item.sys.contentType.sys.id)(item)
          )
        )
      : {}),
    ...formatFeedback('positiveFeedbackForm', positiveFeedbackForm),
    ...formatFeedback('negativeFeedbackForm', negativeFeedbackForm),
  } as T;
};
