import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { EntryFields } from 'contentful';

export const convertToReactComponent = (document: EntryFields.RichText) => {
  return documentToReactComponents(document, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => children,
    },
  });
};
