import { environment } from 'environments/environment';
import { ResultsApi, SendResultsApiRequest } from '@streaming-advisor/results';
import { getAccessToken } from '@soluto-private/mx-app-authentication';

const resultApi = new ResultsApi(environment.apiEndpoint);

export const sendResults = (data: SendResultsApiRequest) => {
  const accessToken = getAccessToken() || '';
  return resultApi.sendResults(data, accessToken);
};
