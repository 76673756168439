import React from 'react';
import styled from 'styled-components';
import { Welcome } from 'components/PageHeader/Welcome';
import { RoutePath } from 'RoutePath';
import { WelcomePageProps } from 'types/PageProps';
import { usePageContentData } from 'hooks/usePageContentData';
import { Tag, colors } from '@soluto-private/mx-asurion-ui-react';
import { WhatToExpectCallout } from 'components/Callout';

const Wrapper = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EstimateTag = styled(Tag)`
  && {
    background: ${colors.neutralBrighter};
    border-radius: 0.125rem;
    padding: 0.25rem;
  }
`;

const LandingPage = () => {
  const { title, subtitle, tag, whatToExpect } =
    usePageContentData<WelcomePageProps>(RoutePath.Welcome);

  return (
    <Wrapper data-testid="HomePage_Container">
      <Welcome title={title} subtitle={subtitle} />
      <EstimateTag type="light" iconSrc="time" text={tag ?? ''} />
      <WhatToExpectCallout resultList={whatToExpect} />
    </Wrapper>
  );
};

export default LandingPage;
