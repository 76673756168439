import { Feedback, FeedbackSkeleton } from '../types';

export const createFeedback = (data: FeedbackSkeleton): Feedback => {
  const {
    entryName,
    title,
    header,
    subheader,
    feedbackChoices,
    textHeader,
    commentsPlaceholder,
    commentsHelperText,
    submitCta,
    completeMessageText,
  } = data.fields;

  return {
    entryName: entryName || '',
    header: header || '',
    subheader: subheader || '',
    feedbackChoices: feedbackChoices || [],
    textHeader: textHeader || '',
    commentsPlaceholder: commentsPlaceholder || '',
    commentsHelperText: commentsHelperText || '',
    submitCta: submitCta || '',
    title: title || '',
    completeMessageText: completeMessageText || '',
  };
};
