import React, { ReactNode, VoidFunctionComponent } from 'react';
import {
  Text,
  breakpoints,
  getFontSize,
  getFontWeight,
} from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

interface WelcomeProps {
  title: string;
  subtitle?: ReactNode | string;
}

const Header = styled(Text)`
  && {
    font-size: ${getFontSize(5)};
    font-weight: ${getFontWeight('heavy')};
    margin: 0;

    ${breakpoints.md} {
      font-size: ${getFontSize(6)};
    }
  }
`;

const SubHeader = styled(Text)`
  && {
    font-size: ${getFontSize(3)};
    margin-top: 0.5rem;

    ${breakpoints.md} {
      font-size: ${getFontSize(4)};
    }
  }
`;

export const Welcome: VoidFunctionComponent<WelcomeProps> = ({
  title,
  subtitle,
}) => (
  <div data-testid="Page_Header">
    <Header as="h1">{title}</Header>
    {Boolean(subtitle) && <SubHeader forwardedAs="h2">{subtitle}</SubHeader>}
  </div>
);
