import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  SpeedTestDelegate,
  WixiLoggerDelegate,
  WixiSnap,
  WixiSession,
  WixiSnapConfig,
  GeoIpDelegate,
  SpeedTestStatus,
} from '@soluto-private/wixi-web-sdk';
import { networkScanState } from 'states/atoms';
import { formatSpeed } from 'utils/NetworkScan';
import { usePageState } from './usePageState';
import { useEffect } from 'react';
import { environment } from 'environments/environment';

export const useNetworkScan = (wixiSnapConfig?: WixiSnapConfig) => {
  const { page, setNetworkState } = usePageState();
  const { downloadSpeed, uploadSpeed } = page.networkState;

  const [networkScan, setNetworkScan] = useRecoilState(networkScanState);
  const resetNetworkScan = useResetRecoilState(networkScanState);
  const { wixiSnap, scanState } = networkScan;

  useEffect(() => {
    console.log('scan state: ', scanState);
  }, [scanState]);

  return {
    scanState: scanState,
    downloadSpeed,
    uploadSpeed,
    downloadSpeedLabel: downloadSpeed && formatSpeed(downloadSpeed).toString(),
    uploadSpeedLabel: uploadSpeed && formatSpeed(uploadSpeed).toString(),
    startScan: async () => {
      if (environment.developmentMode) {
        setTimeout(() => {
          setNetworkScan((netScan) => ({
            ...netScan,
            scanState: SpeedTestStatus.completed,
          }));
          setNetworkState({
            downloadSpeed: '99',
            uploadSpeed: '99',
          });
        }, 3000);

        return;
      }

      const wixiSnap = new WixiSnap(wixiSnapConfig);
      setNetworkScan((netScan) => ({ ...netScan, wixiSnap }));

      WixiSession.onHealthIdChange = (healthId) => {
        console.log('health id: ', healthId);
        setNetworkScan((netScan) => ({
          ...netScan,
          healthId,
        }));
      };

      WixiSession.onGoIdChange = (goId) => {
        console.log('goId: ', goId);
        setNetworkScan((netScan) => ({
          ...netScan,
          goId,
        }));
      };

      GeoIpDelegate.onIspInfoChange = (ispInfo) => {
        console.log('isp info: ', ispInfo);
        setNetworkState({
          geoIpIspInfo: ispInfo,
        });
      };

      SpeedTestDelegate.onUploadSpeedChange = (speed) => {
        console.log('upload speed: ', speed);
        setNetworkState({ uploadSpeed: speed });
      };

      SpeedTestDelegate.onDownloadSpeedChange = (speed) => {
        console.log('download speed: ', speed);
        setNetworkState({ downloadSpeed: speed });
      };

      SpeedTestDelegate.onStatusChange = (status) => {
        setNetworkScan((netScan) => ({
          ...netScan,
          scanState: status,
        }));
      };

      WixiLoggerDelegate.onLog = (logMessage) => {
        console.log('log: ', logMessage);
      };

      await wixiSnap.snap();
    },
    cancelScan: () => {
      wixiSnap?.cancel();
      resetNetworkScan();
      setNetworkState({
        downloadSpeed: '',
        uploadSpeed: '',
        geoIpIspInfo: undefined,
      });

      SpeedTestDelegate.onUploadSpeedChange = undefined;
      SpeedTestDelegate.onDownloadSpeedChange = undefined;
      SpeedTestDelegate.onStatusChange = undefined;
    },
  };
};
