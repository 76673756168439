import { Environment } from './models/environment.model';

export const environment: Environment = {
  production: true,
  wixiPartnerConfig: {
    partnerSecret: 'yqZNNyPHvr5HAFPDJ',
    partnerAppId: 'servicesite-web',
    partnerId: 'att',
    partnerAppSecret: 'u6EtdpyLjryTX3PQp',
  },
  apiEndpoint: 'https://api.streaming-advisor.solutonash.com/v1',
  appConfigApiEndpoint: 'https://config.streaming-advisor.solutonash.com/v1',
  contentfulEnv: 'main',
  asurionIdConfig: {
    apiDomain: 'https://id.asurion.com',
    clientId: '4c007156-ec0d-48d3-8bbb-34c07884e693',
  },
};
