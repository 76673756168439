import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RoutePath } from 'RoutePath';
import { useFlow } from 'hooks/useFlow';
import { MainTitle } from 'components/Article/Results/ResultsOverviewTitle';
import { ResultsOverviewButtons } from './ResultsOverviewButtons';
import { SpeedResults } from './DeviceSpeedScore';
import { ImpactList, SupportList } from './List';
import { ResultsPageProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';
import { usePageState } from 'hooks/usePageState';
import { AnalyticEvents, trackEvent } from '@streaming-advisor/analytics';
import { FeedbackForm } from 'components/Feedback';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding-bottom: 2rem;
`;

export const ResultsOverview = () => {
  const {
    networkSupportList,
    thingsThatImpactSpeed,
    networkSpeedResults,
    networkSpeedDesc: { value: networkSpeedDescription },
    title,
    subtitle,
    chatButton,
    callButton,
    backButton,
    negativeFeedbackForm,
    positiveFeedbackForm,
  } = usePageContentData<ResultsPageProps>(RoutePath.Results);

  const { goTo } = useFlow();

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: () => goTo(RoutePath.WifiQ2),
  });

  const {
    page: { results },
  } = usePageState();

  useEffect(() => {
    const data = {
      resultCode: results?.user_code || '',
      isp2RouterDwnldSpeed: results?.router_test || '',
      isp2RouterUpldSpeed: null,
      Router2DeviceDwnldSpeed: results?.speed_test.download || '',
      Router2DeviceUpldSpeed: results?.speed_test.upload || '',
    };

    trackEvent(AnalyticEvents.RESULT, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <section>
        <MainTitle title={title} subtitle={subtitle} />
        <ResultsOverviewButtons
          chatButton={chatButton}
          callButton={callButton}
        />
      </section>
      <SpeedResults
        networkSpeedResults={networkSpeedResults}
        networkSpeedDescription={networkSpeedDescription}
      />
      <SupportList networkSupportList={networkSupportList} />
      <ImpactList thingsThatImpactSpeed={thingsThatImpactSpeed} />
      <FeedbackForm
        negativeFeedbackForm={negativeFeedbackForm}
        positiveFeedbackForm={positiveFeedbackForm}
      />
    </Wrapper>
  );
};
