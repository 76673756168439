import {
  getDispatcher as _getDispatcher,
  getIdentity,
} from '@soluto-private/mx-analytics';

export const getDispatcher = () =>
  _getDispatcher({
    path: '/streaming-advisor',
    packageName: '@soluto-private/streaming-advisor',
    friendlyName: 'StreamingAdvisor',
    ignoreScope: true,
  });

export const getUserBrowserId = () => getIdentity('UserBrowserId');

export class Analytics {
  private analyticProperties: Record<string, unknown> = {};

  get currentAnalyticProperties(): Record<string, unknown> {
    return this.analyticProperties;
  }

  public updateProperties = (newProps: Record<string, unknown>): void => {
    this.analyticProperties = { ...this.analyticProperties, ...newProps };
  };
}
const analytics = new Analytics();

export const trackEvent = (
  eventName: string,
  properties?: Record<string, unknown>
) => {
  getDispatcher().dispatch(eventName, {
    ExtraData: { ...analytics.currentAnalyticProperties, ...properties },
  });
};

export const updateStickyProps = (props: Record<string, unknown>) => {
  analytics.updateProperties(props);
};
