import {
  SpeedTestDelegate,
  SpeedTestStatus,
  SpeedtestType,
  WixiSnap,
} from '@soluto-private/wixi-web-sdk';
import { usePageState } from './usePageState';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { routerState } from 'states/atoms/routerState';
import { environment } from 'environments/environment';

export const useRouterScan = () => {
  const { setRouterState } = usePageState();
  const [routerScan, setRouterScan] = useRecoilState(routerState);
  const resetState = useResetRecoilState(routerState);

  const startScan = async () => {
    const wixiSnap = new WixiSnap({
      wixiHealthConfig: {
        speedtestType: SpeedtestType.DOWNLOAD,
      },
    });

    setRouterScan((rscan) => ({ ...rscan, wixiSnap }));

    SpeedTestDelegate.onDownloadSpeedChange = (speed) => {
      console.log('download speed: ', speed);
      setRouterState({ downloadSpeed: speed });
    };

    SpeedTestDelegate.onUploadSpeedChange = undefined;

    SpeedTestDelegate.onStatusChange = (status) => {
      setRouterScan((rscan) => ({
        ...rscan,
        scanState: status,
      }));
    };

    await wixiSnap.snap();
  };

  return {
    startScan: () => {
      if (environment.developmentMode) {
        setTimeout(() => {
          setRouterState({
            downloadSpeed: '99',
          });
          setRouterScan((rscan) => ({
            ...rscan,
            scanState: SpeedTestStatus.completed,
          }));
        }, 3000);

        return;
      }

      setTimeout(() => startScan(), 0);
    },
    scanState: routerScan.scanState,
    cancelScan: () => {
      routerScan.wixiSnap?.cancel();
      resetState();
      setRouterState(null);

      SpeedTestDelegate.onDownloadSpeedChange = undefined;
      SpeedTestDelegate.onStatusChange = undefined;
    },
  };
};
