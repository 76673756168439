import styled from 'styled-components';
import { StarSelection } from './StarSelection';
import {
  Text,
  Textarea,
  ManualSelectionChips,
  Button,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { FC, useMemo, useState } from 'react';
import { AnalyticEvents, trackEvent } from '@streaming-advisor/analytics';
import { Feedback } from '@streaming-advisor/contentful';

const Container = styled.div`
  max-width: 29.44rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2.188rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeedf1;
`;

type FormDataType = {
  stars: number;
  feedback: string;
  feedbackChips: string[];
};

type FeedbackFormProps = {
  negativeFeedbackForm: Feedback;
  positiveFeedbackForm: Feedback;
};

const formDataInit = {
  stars: 0,
  feedback: '',
  feedbackChips: [],
};

export const FeedbackForm: FC<FeedbackFormProps> = ({
  negativeFeedbackForm,
  positiveFeedbackForm,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [formData, setFormData] = useState<FormDataType>(formDataInit);

  const content = useMemo(() => {
    if (formData.stars < 5) {
      return negativeFeedbackForm;
    }
    return positiveFeedbackForm;
  }, [formData.stars, negativeFeedbackForm, positiveFeedbackForm]);

  const onChangeChips = (value: string) => {
    setFormData((oldFormData) => {
      const feedbackChips = oldFormData.feedbackChips.includes(value)
        ? oldFormData.feedbackChips.filter((str) => str !== value)
        : [...oldFormData.feedbackChips, value];

      return {
        ...oldFormData,
        feedbackChips,
      };
    });
  };

  const chipsData = useMemo(
    () =>
      content.feedbackChoices.map((text) => ({
        hideDefaultIcon: true,
        label: text,
        isSelected: formData.feedbackChips.includes(text),
        onClick: () => onChangeChips(text),
      })),
    [content.feedbackChoices, formData.feedbackChips]
  );

  const onSubmit = () => {
    setIsCompleted(true);
    trackEvent(AnalyticEvents.FEEDBACK, formData);
  };

  return (
    <Container>
      <Divider />
      <Form onSubmit={(e) => e.preventDefault()}>
        <Text size={4} weight="heavy">
          {isCompleted ? content.completeMessageText : content.title}
        </Text>
        <StarSelection
          disabled={isCompleted}
          activeStarsCount={formData.stars}
          setActiveStarsCount={(newStars) => {
            setFormData((oldFormData) => ({
              ...oldFormData,
              feedbackChips: [],
              stars: newStars,
            }));
          }}
        />
        {formData.stars > 0 && !isCompleted && (
          <>
            <Text size={3}>{content.header}</Text>
            <Text size={2}>{content.subheader}</Text>
            <ManualSelectionChips
              chips={chipsData}
              layout="wrap"
              options={{
                edgeItemsMargin: '0px',
              }}
              size="small"
              type="selection"
              variant="normal"
            />
            <Text size={3}>{content.textHeader}</Text>
            <Textarea
              label={content.commentsPlaceholder}
              helperText={content.commentsHelperText}
              maxCharCount={200}
              value={formData.feedback}
              onChange={(e) => {
                setFormData((oldFormData) => ({
                  ...oldFormData,
                  feedback: e.target.value,
                }));
              }}
            />
            <div>
              <Button onClick={onSubmit}>{content.submitCta}</Button>
            </div>
          </>
        )}
      </Form>
    </Container>
  );
};
