import React from 'react';
import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import { useNetworkScan } from 'hooks/useNetworkScan';
import LandingPage from './Landing';
import { RoutePath } from 'RoutePath';
import { withClickAnalytics } from '@streaming-advisor/analytics';
import { WelcomePageProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';

const Button = withClickAnalytics(AsurionButton);

const Welcome = () => {
  const { goTo } = useFlow();
  const { scanState } = useNetworkScan();
  const { getStartedButton, skipButton, backButton } =
    usePageContentData<WelcomePageProps>(RoutePath.Welcome);

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: () => {
      window.location.assign(window.location.origin + '/dashboard/services');
    },
  });

  const buttonRightGroup = (
    <>
      {skipButton && (
        <Button
          data-testid="WelcomePage_SkipBtn"
          variant="outline"
          color="secondary"
          onClick={() => {
            goTo(RoutePath.OnlineActivitiesQ1);
          }}
        >
          {skipButton.title}
        </Button>
      )}
      <Button
        data-testid="WelcomePage_ContinueBtn"
        color="secondary"
        onClick={() => {
          const nextPath =
            scanState === SpeedTestStatus.completed
              ? RoutePath.TestComplete
              : RoutePath.NetworkTest;
          goTo(nextPath);
        }}
      >
        {getStartedButton.title}
      </Button>
    </>
  );

  return (
    <Container
      steps={['inprogress']}
      footer={{
        buttonRightGroup,
      }}
      showSidebar={false}
    >
      <LandingPage />
    </Container>
  );
};

export default Welcome;
