import {
  expandChatOverlay,
  hideChatBubble,
  initialize,
  showChatBubble,
  attachSDKToContainer,
  injectChatScriptByVersion,
  showMessage,
} from '@soluto-private/remote-expert-sdk';
import {
  Channel,
  Language,
  MessagingOverlayStateChangedEventArgs,
  MessagingTopic,
  Partner,
  SessionChangedEventArgs,
} from '@soluto-private/remote-expert-sdk/dist/exportedTypes';
import { EventEmitter } from 'events';
import { Analytics } from 'react-shisell';
import { AnalyticEvents } from '@streaming-advisor/analytics';
import { withExtras } from 'shisell/extenders';

export class RemoteExpertChat {
  private static async _init(
    isProduction: boolean,
    partner: string,
    token: string,
    eventsHandler: (eventEmitter: EventEmitter) => void
  ): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      injectChatScriptByVersion(
        {
          id: 'remote-expert-sdk',
          version: 'v1',
          environment: isProduction ? 'production' : 'development',
        },
        async () => {
          try {
            await initialize({
              partner: partner as Partner,
              messagingTopic: MessagingTopic.GENERAL_CONVERSATION,
              appName: 'Streaming-Advisor',
              language: Language.EN_US,
              token,
              channel: Channel.CHAT,
              registerToEvents: eventsHandler,
              environment: isProduction ? 'production' : 'development',
            });

            resolve();
          } catch (e) {
            console.log(`Error initializing Twilio Chat SDK: ${e as string}`);
            reject(e);
          }
        }
      ).catch((e: any) => reject(e));
    });
  }

  static async init(
    isProduction: boolean,
    partner: string,
    token: string,
    analytics: Analytics
  ): Promise<void> {
    if (typeof partner === 'undefined') {
      return;
    }

    const eventsHandler = (eventEmitter: EventEmitter) => {
      eventEmitter.on(
        'FABIO_CLICKED',
        (args: MessagingOverlayStateChangedEventArgs) => {
          analytics?.dispatcher
            .extend(
              withExtras({
                ActionId: `FABIO_CLICKED_${args.isExpanded ? 'open' : 'close'}`,
              })
            )
            .dispatch(AnalyticEvents.CLICK);
        }
      );

      eventEmitter.on('SESSION_CHANGED', (args: SessionChangedEventArgs) => {
        analytics?.dispatcher
          .extend(
            withExtras({
              SessionChanged: args.action,
              SessionId: args.sessionId,
              ExpertId: args.expertId,
              Expertise: args.expertise,
              Pool: args.pool,
            })
          )
          .dispatch(AnalyticEvents.AE_SESSION_CHANGED);
      });
    };

    await this._init(isProduction, partner, token, eventsHandler);
  }

  static showMessaging(): void {
    try {
      showChatBubble();
    } catch (e) {
      console.log(`Twilio SDK ERROR: ${e as string}`);
    }
  }

  static hideMessaging(): void {
    try {
      hideChatBubble();
    } catch (e) {
      console.log(`Twilio SDK ERROR: ${e as string}`);
    }
  }

  static openMessagingOverlay(message?: string): void {
    try {
      expandChatOverlay({ message });
    } catch (e) {
      console.log(`Twilio SDK ERROR: ${e as string}`);
    }
  }

  static sendHiddenMessage(message: string): void {
    showMessage(message, true);
  }

  static async sendMessage(type: string, message: string): Promise<void> {
    if (type === 'CustomerMessage') {
      showMessage(message, true);
    }
  }

  static async attachToContainer(containerId: string) {
    try {
      attachSDKToContainer(containerId);
    } catch (e) {
      console.log(`Twilio SDK ERROR: ${e as string}`);
    }
  }
}

export type TwilioClientType = typeof RemoteExpertChat;
