import {
  Icon,
  IconProps,
  Text,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { Item } from '@streaming-advisor/contentful';
import { usePageState } from 'hooks/usePageState';
import { useSkipRouterTest } from 'hooks/useSkipRouterTest';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ResultsPageProps } from 'types/PageProps';
import { formatSpeed } from 'utils/NetworkScan';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 29.44rem;
`;

const Card = styled.div`
  border: 0.125rem solid #c6c6ca;
  box-shadow: none;
  border-radius: 0.75rem;
  border-width: 0.125rem;
  text-align: initial;
  padding: 1rem;
`;

const CustomText = styled(Text)`
  font-weight: 400;
  font-size: ${fontSizes[4]};
  line-height: 1.3;
  display: flex;
  text-align: left;
`;

const SpeedScores = styled.div<{ skipRouterTest: boolean }>`
  display: grid;
  grid-template-columns: ${({ skipRouterTest = false }) =>
    skipRouterTest ? 'auto auto' : 'auto auto auto'};
  margin-top: 0.75rem;
  gap: 1.5rem;
`;

const SpeedScore = styled.div`
  padding: 0px;
  /* max-height: 4.0625rem; */
  width: auto;
  text-align: center;
  display: flex;
  flex-direction: column;

  :first-child {
    grid-column: 1 / -1;
  }

  ${breakpoints.md} {
    :first-child {
      grid-column: auto;
    }
  }
`;

const SpeedTitle = styled(Text).attrs({ forwardedAs: 'h6' })`
  &&& {
    flex-grow: 1;
    margin-bottom: 0.62rem;
    font-weight: ${fontWeights.heavy};
    text-align: left;
  }
`;

const Result = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const IconBadge = styled(Icon)`
  background: ${colors.neutralBrightest};
  padding: 0.19rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreDetail = styled(Text)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1;
  color: #999999;
`;

type ScoreType = {
  icon: IconProps['src'];
  title: ReactNode;
  iconTitle: string;
  children: ReactNode;
  measurement?: string;
};

const Score: FC<ScoreType> = ({
  title,
  icon,
  iconTitle,
  children,
  measurement = 'Mbps',
}) => (
  <SpeedScore>
    <SpeedTitle>{title}</SpeedTitle>
    <Result>
      <IconBadge aria-hidden="true" size="large" src={icon} title={iconTitle} />
      <Text size={5}>{children}</Text>
      <ScoreDetail>{measurement}</ScoreDetail>
    </Result>
  </SpeedScore>
);

type Props = {
  networkSpeedResults: ResultsPageProps['networkSpeedResults'];
  networkSpeedDescription: string;
};

const icons: Record<string, string> = {
  routerSpeed: 'Router',
  downloadSpeed: 'ArrowDown',
  uploadSpeed: 'ArrowUp',
};

export const SpeedResults: FC<Props> = ({
  networkSpeedResults,
  networkSpeedDescription,
}) => {
  const {
    page: {
      networkState: { downloadSpeed, uploadSpeed },
      routerState,
    },
  } = usePageState();

  // TODO: Remove feature flag check once A/B test is concluded.
  const skipRouterTest = useSkipRouterTest();

  const scores: Record<string, string | number> = {
    routerSpeed: formatSpeed(routerState?.downloadSpeed || '0') || '-',
    downloadSpeed: formatSpeed(downloadSpeed) || '-',
    uploadSpeed: formatSpeed(uploadSpeed) || '-',
  };

  const renderScore = (item: Item) => {
    // Don't render router speed if skipRouterTest is enabled.
    if (skipRouterTest && item.value === 'routerSpeed') {
      return null;
    }

    return (
      <Score
        key={item.value}
        title={item.title}
        icon={icons?.[item.value]}
        iconTitle={item.value}
        measurement={item.label}
      >
        {scores?.[item.value]}
      </Score>
    );
  };

  return (
    <CardContainer>
      <Card>
        <CustomText forwardedAs="h3" size={3}>
          {networkSpeedResults.title}
        </CustomText>
        <SpeedScores skipRouterTest={skipRouterTest}>
          {networkSpeedResults.items.map((item) => renderScore(item))}
        </SpeedScores>
      </Card>
      <Text size={1} as="p" color={colors.neutralDeeper}>
        {networkSpeedDescription}
      </Text>
    </CardContainer>
  );
};
