import { RoutePath } from 'RoutePath';
import { useNavigate } from 'react-router';

export const useFlow = () => {
  const navigate = useNavigate();

  const goTo = (route: RoutePath) => {
    navigate(route);
  };

  return {
    goTo,
  };
};
