import { Container } from 'components/Main';
import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { withClickAnalytics } from '@streaming-advisor/analytics';
import { RoutePath } from 'RoutePath';
import { useFlow } from 'hooks/useFlow';
import { useRouterScan } from 'hooks/useRouterScan';
import RouterSpeedTest from './RouterSpeedTest';
import { useRandomizedProgress } from 'hooks/useRandomizedProgress';
import { usePageState } from 'hooks/usePageState';
import { useEffect } from 'react';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { RouterSpeedProgressProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';

const Button = withClickAnalytics(AsurionButton);

const RouterScanProgress = () => {
  const { startScan, cancelScan, scanState } = useRouterScan();
  const { goTo } = useFlow();
  const { page } = usePageState();
  const { title, subtitle, cancelButton, backButton } =
    usePageContentData<RouterSpeedProgressProps>(RoutePath.RouterSpeedProgress);

  const isScanCompleted = scanState === SpeedTestStatus.completed;

  const { progress } = useRandomizedProgress(isScanCompleted);

  useEffect(() => {
    startScan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: () => {
      cancelScan();
      goTo(RoutePath.TestComplete);
    },
  });

  useEffect(() => {
    if (isScanCompleted) {
      goTo(RoutePath.RouterSpeedCompleted);
    }
  }, [goTo, isScanCompleted]);

  const handleBack = () => {
    cancelScan();
    goTo(RoutePath.TestComplete);
  };

  return (
    <Container
      steps={['inprogress']}
      footer={{
        onBack: () => goTo(RoutePath.TestComplete),
        backButtonText: backButton?.title,
        buttonRightGroup: (
          <Button onClick={handleBack} variant="outline" color="secondary">
            {cancelButton.title}
          </Button>
        ),
      }}
    >
      <RouterSpeedTest
        title={title}
        subtitle={subtitle}
        progress={progress}
        downloadSpeed={page.routerState?.downloadSpeed || ''}
      />
    </Container>
  );
};

export default RouterScanProgress;
