import React, { FC } from 'react';
import { Container } from 'components/Main';
import {
  Button as AsurionButton,
  CheckboxSelectionCard as _CheckboxSelectionCard,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import QuestionTextWrapper from './QuestionTextWrapper';
import { SlowWifiValues } from '@streaming-advisor/questions';
import { usePageState } from 'hooks/usePageState';
import { RoutePath } from 'RoutePath';
import { useFlow } from 'hooks/useFlow';
import {
  withClickAnalytics,
  withSubmitAnalytics,
} from '@streaming-advisor/analytics';
import { WifiQuestionProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0.75rem;

  > label {
    width: 100%;
  }
`;

const Button = withClickAnalytics(AsurionButton);
const SubmitButton = withSubmitAnalytics(AsurionButton);

const CheckboxSelectionCard = styled(_CheckboxSelectionCard)`
  div {
    justify-content: flex-start;
  }
`;

const Question2: FC = () => {
  const { goTo } = useFlow();
  const {
    page: { questionState },
    setQuestionState,
  } = usePageState();
  const {
    title,
    subtitle,
    slowWifiQuestions,
    skipButton,
    continueButton,
    backButton,
    tag,
  } = usePageContentData<WifiQuestionProps>(RoutePath.WifiQ2);

  const onBack = () => {
    goTo(RoutePath.OnlineActivitiesQ1);
  };

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack,
  });

  const onSetSelected = (key: SlowWifiValues) => {
    const { q1, q2 } = questionState;

    const isNeverSlow = key === 'neverSlow';
    if (q2.includes(key)) {
      const newQ2 = q2.filter((item) => item !== key);
      setQuestionState({
        q1,
        q2: newQ2,
      });
    } else {
      const checkedItems = [...q2, key].filter((key) => key !== 'neverSlow');
      setQuestionState({
        q1,
        q2: isNeverSlow ? ['neverSlow'] : checkedItems,
      });
    }
  };

  const onNext = () => {
    goTo(RoutePath.WifiLoading);
  };

  const buttonRightGroup = (
    <>
      <Button onClick={onNext} variant="outline" color="secondary">
        {skipButton.title}
      </Button>
      <SubmitButton
        onClick={onNext}
        disabled={!questionState.q2.length}
        color="secondary"
        analyticsData={{
          slowWifiOptions: questionState.q2,
        }}
      >
        {continueButton.title}
      </SubmitButton>
    </>
  );

  return (
    <Container
      steps={['completed', 'inprogress']}
      footer={{
        onBack,
        buttonRightGroup,
        backButtonText: backButton?.title,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          tag={tag || 'Question'}
          question={2}
          title={title}
          subtitle={subtitle}
          titleId="Question_Title"
          subtitleId="Question_Subtitle"
        />
        <CheckboxWrapper>
          {slowWifiQuestions.items.map((item) => (
            <CheckboxSelectionCard
              key={item.value}
              checked={questionState.q2.includes(item.value as SlowWifiValues)}
              onChange={() => onSetSelected(item.value as SlowWifiValues)}
            >
              {item.title}
            </CheckboxSelectionCard>
          ))}
        </CheckboxWrapper>
      </Wrapper>
    </Container>
  );
};

export default Question2;
