import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  Link,
  Button,
  ButtonGroup,
} from '@soluto-private/mx-asurion-ui-react-v3';
import {
  AnalyticClickTypes,
  withClickAnalytics,
} from '@streaming-advisor/analytics';
import { RemoteExpertChat } from '@streaming-advisor/chat';
import { ResultsPageProps } from 'types/PageProps';

const ButtonStyle = css`
  &&& {
    width: 100%;

    ${breakpoints.md} {
      width: auto;
    }
  }
`;

const CustomButton = styled(withClickAnalytics(Button))`
  ${ButtonStyle}
`;

const CustomLink = styled(withClickAnalytics(Link))`
  ${ButtonStyle}
`;

const CustomButtonGroup = styled(ButtonGroup)`
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  align-items: stretch;
`;

type Props = {
  chatButton: ResultsPageProps['chatButton'];
  callButton: ResultsPageProps['callButton'];
};

export const ResultsOverviewButtons: FC<Props> = ({
  chatButton,
  callButton,
}) => (
  <CustomButtonGroup data-testid="Results_ChatContainer" alignment="start">
    <CustomButton
      data-testid="Results_ChatExpertBtn"
      color="secondary"
      iconSide="left"
      iconSrc={chatButton.iconName}
      onClick={() => {
        RemoteExpertChat.openMessagingOverlay();
      }}
      variant="outline"
      analyticsData={{
        type: AnalyticClickTypes.EXPERT_CHAT_CLICKED,
      }}
    >
      {chatButton.title}
    </CustomButton>
    <CustomLink
      data-testid="Results_CallExpertBtn"
      href={`tel:${callButton.value}`}
      variant="button"
      color="secondary"
      iconSide="left"
      iconSrc={callButton.iconName}
      analyticsData={{
        type: AnalyticClickTypes.EXPERT_CALL_CLICKED,
      }}
    >
      {callButton.title}
    </CustomLink>
  </CustomButtonGroup>
);
