import { getUserBrowserId } from '@streaming-advisor/analytics';
import { useEffect } from 'react';
import { useConfigState } from './useConfigState';
import { useGetFeatureFlag } from './useGetFeatureFlag';

export const useSkipRouterTest = () => {
  const {
    config: {
      featureOverrides: { skipRouterTest: srtOverride },
    },
    setSkipRouterTest,
  } = useConfigState();

  const { feature: srtFeatureFlag } = useGetFeatureFlag('skipRouterTest', {
    userBrowserId: getUserBrowserId(),
  });

  const urlParams = new URLSearchParams(window.location.search);
  const srtParam = urlParams.get('skipRouterTest');
  const overrideValue = srtParam?.toLowerCase() === 'true';

  // override only if query param is defined and different from current config state
  const shouldSetOverride = srtParam !== null && overrideValue !== srtOverride;

  useEffect(() => {
    if (shouldSetOverride) {
      setSkipRouterTest(overrideValue);
    }
  }, [shouldSetOverride, overrideValue, setSkipRouterTest]);

  // Priority
  // 1. override value from query param, or cached value from config state
  // 2. feature flag value from app config
  // 3. default value = false
  return srtOverride ?? srtFeatureFlag?.enabled ?? false;
};
