import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import {
  Button as AsurionButton,
  CheckboxSelectionCard,
  DynamicGrid,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import {
  withClickAnalytics,
  withSubmitAnalytics,
} from '@streaming-advisor/analytics';
import { OnlineActivityValues } from '@streaming-advisor/questions';
import { Container } from 'components/Main';
import { useCheckIfMobileSize } from 'hooks/useCheckIfMobileSize';
import { useFlow } from 'hooks/useFlow';
import { usePageContentData } from 'hooks/usePageContentData';
import { usePageState } from 'hooks/usePageState';
import { useSkipRouterTest } from 'hooks/useSkipRouterTest';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { RoutePath } from 'RoutePath';
import { OnlineActivitiesProps } from 'types/PageProps';
import QuestionTextWrapper from './QuestionTextWrapper';

const Wrapper = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CheckboxCard = styled(CheckboxSelectionCard)`
  position: relative;
  overflow: hidden;
  height: 10.5rem;

  > div {
    position: absolute;
    right: 0;
    padding: 0;
  }
`;

const CheckboxInner = styled.div`
  display: flex;
  width: 100%;
  height: 8.75rem;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.5rem;
`;

const CheckboxTitle = styled(Text)`
  text-align: center;
`;

const CustomGrid = styled(DynamicGrid)`
  width: 100%;
`;

const CheckboxImg = styled.img`
  width: 5.25rem;
  height: 5.25rem;
`;

const Button = withClickAnalytics(AsurionButton);
const SubmitButton = withSubmitAnalytics(AsurionButton);

type CustomCheckboxProps = {
  checked: boolean;
  onChange: () => void;
  icon?: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  altLabel?: string;
};

const Checkbox: FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  icon,
  title,
  subtitle,
  altLabel,
}) => (
  <CheckboxCard checked={checked} onChange={onChange}>
    <CheckboxInner>
      <CheckboxImg alt={altLabel} src={icon} />
      <CheckboxTitle>
        <Text as="p" style={{ padding: '0 10px' }}>
          {title}
        </Text>
        <Text as="p" color="#333F48">
          {subtitle}
        </Text>
      </CheckboxTitle>
    </CheckboxInner>
  </CheckboxCard>
);

const Question1: FC = () => {
  const { goTo } = useFlow();
  const isMobileSize = useCheckIfMobileSize();
  const {
    page: { questionState },
    setQuestionState,
  } = usePageState();

  const {
    title,
    subtitle,
    tag,
    backButton,
    skipButton,
    continueButton,
    onlineActivitiesQuestion,
  } = usePageContentData<OnlineActivitiesProps>(RoutePath.OnlineActivitiesQ1);

  // TODO: Remove feature flag check once A/B test is concluded.
  const skipRouterTest = useSkipRouterTest();

  const onBack = () => {
    if (skipRouterTest) {
      goTo(RoutePath.TestComplete);
    } else {
      goTo(RoutePath.RouterSpeedCompleted);
    }
  };

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack,
  });

  const onSetSelected = (key: OnlineActivityValues) => {
    const { q1, q2 } = questionState;

    const isNoneOfThese = key === 'noneOfThese';
    if (q1.includes(key)) {
      const newQ1 = q1.filter((item) => item !== key);
      setQuestionState({
        q2,
        q1: newQ1,
      });
    } else {
      const checkedItems = [...q1, key].filter((key) => key !== 'noneOfThese');
      setQuestionState({
        q2,
        q1: isNoneOfThese ? ['noneOfThese'] : checkedItems,
      });
    }
  };

  const onNext = () => {
    goTo(RoutePath.WifiQ2);
  };

  const buttonRightGroup = (
    <>
      <Button onClick={onNext} variant="outline" color="secondary">
        {skipButton.title}
      </Button>
      <SubmitButton
        disabled={!questionState.q1.length}
        onClick={onNext}
        color="secondary"
        analyticsData={{
          onlineActivitiesOptions: questionState.q1,
        }}
      >
        {continueButton.title}
      </SubmitButton>
    </>
  );

  return (
    <Container
      steps={['completed', 'inprogress']}
      footer={{
        onBack,
        buttonRightGroup,
        backButtonText: backButton?.title,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          tag={tag || 'Question'}
          question={1}
          title={title}
          subtitle={subtitle}
          titleId="Question_Title"
          subtitleId="Question_Subtitle"
        />
        <CustomGrid autoFit={true} minWidth={isMobileSize ? 8.5 : 12}>
          {onlineActivitiesQuestion.items.map((item) => (
            <Checkbox
              key={item.value}
              checked={questionState.q1.includes(
                item.value as OnlineActivityValues
              )}
              icon={item.image}
              title={item.title}
              subtitle={item.subtitle}
              altLabel={item.label}
              onChange={() => onSetSelected(item.value as OnlineActivityValues)}
            />
          ))}
        </CustomGrid>
      </Wrapper>
    </Container>
  );
};

export default Question1;
