import { ContentTypes, ListSkeleton } from '../types';
import { getFactoryByType } from './factoriesByType';

export const createList = (data: ListSkeleton) => {
  const { codeId, title, items } = data.fields;
  return {
    [codeId]: {
      title: title || '',
      items: (items || []).map((item) =>
        getFactoryByType(ContentTypes.ITEM)(item, false)
      ),
    },
  };
};
