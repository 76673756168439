import React, { ReactNode } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import RouterSpeedTestContainer from './components/RouterSpeedTestContainer';

const PageContainer = styled.div`
  height: auto;
`;

type RouterSpeedTestProps = {
  downloadSpeed: string;
  progress: number;
  title: string;
  subtitle: ReactNode;
};

const RouterSpeedTest = ({
  title,
  subtitle,
  downloadSpeed,
  progress,
}: RouterSpeedTestProps) => {
  return (
    <PageContainer data-testid="RouterPage_Container">
      <Welcome title={title} subtitle={subtitle} />
      <RouterSpeedTestContainer
        progress={progress}
        downloadSpeed={downloadSpeed}
      />
    </PageContainer>
  );
};

export default RouterSpeedTest;
