import { ContentTypes } from '../types';
import { createExpertProgress } from './ExpertProgressFactory';
import { createFeedback } from './FeedbackFactory';
import { createItem } from './ItemFactory';
import { createList } from './ListFactory';
import { createPage } from './PageFactory';
import { createTestContent } from './TestContentFactory';

export const getFactoryByType = (
  contentType: ContentTypes
): CallableFunction => {
  const mapping = {
    [ContentTypes.PAGE]: createPage,
    [ContentTypes.EXPERT_PROGRESS]: createExpertProgress,
    [ContentTypes.LIST]: createList,
    [ContentTypes.ITEM]: createItem,
    [ContentTypes.TEST_CONTENT]: createTestContent,
    [ContentTypes.FEEDBACK]: createFeedback,
  };
  return mapping[contentType];
};
