import { useQuery } from 'react-query';

import { useMxContext } from '@soluto-private/mx-context-react';

import { fetchFromContentful } from '../ContentfulClient';
import { ContentTypes, ExpertProgressSkeleton } from '../types';
import { createExpertProgress } from '../factories';
import { validateLanguage } from '../utils';

export const useExpertProgressData = (
  environment: string,
  codeId: string,
  isPreview?: boolean
) => {
  const { client = 'att', language } = useMxContext();

  const { data } = useQuery(
    [
      environment, /// Contentful environment
      'contentful',
      {
        content_type: ContentTypes.EXPERT_PROGRESS,
        'fields.codeId[in]': codeId,
        'fields.partner[in]': `all,${client}`,
        limit: 1,
        locale: validateLanguage(language),
      },
      isPreview,
    ],
    fetchFromContentful<ExpertProgressSkeleton>,
    { staleTime: Infinity }
  );

  return {
    ...createExpertProgress({
      ...data?.items[0],
      contentTypeId: ContentTypes.EXPERT_PROGRESS,
    } as ExpertProgressSkeleton),
  };
};
