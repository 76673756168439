import { ExpertProgress, ExpertProgressSkeleton } from '../types';
import { convertToReactComponent } from '../utils';

export const createExpertProgress = (
  data: ExpertProgressSkeleton
): ExpertProgress => {
  const { title, subtitle, shiftSchedule, image, steps } = data.fields;

  return {
    title: convertToReactComponent(title) || null,
    subtitle: convertToReactComponent(subtitle) || null,
    shiftSchedule: shiftSchedule || '',
    image: image.fields.file?.url || '',
    steps: steps || [],
  };
};
