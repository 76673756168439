import {
  AsurionDoodleSpinner,
  Text,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { RoutePath } from 'RoutePath';
import { Container } from 'components/Main';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useFlow } from 'hooks/useFlow';
import { sendResults } from 'utils/sendResults';
import { usePageState } from 'hooks/usePageState';
import { type PageState } from 'states/atoms';
import { formatSpeed } from 'utils/NetworkScan';
import { useNavigate } from 'react-router-dom';
import { DefaultPageProps } from 'types/PageProps';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { usePageContentData } from 'hooks/usePageContentData';
import { calculateCapabilities } from 'utils/calculateCapabilities';

const Wrapper = styled.div`
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10.641px;

  ${breakpoints.md2} {
    padding-top: 6.25rem;
  }
`;

const useCallResultsApi = () => {
  const navigate = useNavigate();
  const { goTo } = useFlow();
  const { page, setResultsState } = usePageState();
  const { questionState, routerState, networkState }: PageState = page;

  useEffect(() => {
    const postResults = () =>
      sendResults({
        questions: {
          household_online_activities: questionState.q1,
          slow_wifi_experience: questionState.q2,
        },
        speed_test: {
          upload: formatSpeed(networkState.uploadSpeed),
          download: formatSpeed(networkState.downloadSpeed),
        },
        router_test: formatSpeed(routerState?.downloadSpeed ?? ''),
        isp: networkState.geoIpIspInfo?.isp ?? '',
        isp_code: networkState.geoIpIspInfo?.isp_code ?? '',
        capabilities: calculateCapabilities(
          formatSpeed(networkState.downloadSpeed)
        ),
      });

    (async () => {
      let retryCount = 3;
      while (retryCount !== 0) {
        retryCount--;

        try {
          const results = await postResults();
          console.log('post results: ', results);
          setResultsState(results);
          goTo(RoutePath.Results);
          return;
        } catch (error) {
          console.log('post results error:', error);
          if (retryCount === 0) {
            // TODO: replace this to throw error
            navigate('/404');
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const WifiLoading = () => {
  const { goTo } = useFlow();
  const onBack = () => goTo(RoutePath.WifiQ2);
  const { title, backButton } = usePageContentData<DefaultPageProps>(
    RoutePath.WifiLoading
  );
  useCallResultsApi();

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack,
  });

  return (
    <Container
      steps={['completed', 'completed', 'inprogress']}
      footer={{
        onBack,
        backButtonText: backButton?.title,
      }}
    >
      <Wrapper>
        <AsurionDoodleSpinner width="85.126px" />
        <Text data-testid="WifiLoading_TextLoader" size={3} weight="base">
          {title}
        </Text>
      </Wrapper>
    </Container>
  );
};

export default WifiLoading;
