import * as contentful from 'contentful';
import { EntryCollection } from 'contentful';
import { PageDataQuery } from './types';

const getContentfulClient = (environment: string, isPreview?: boolean) =>
  contentful.createClient({
    space: 'r74xo9yv27nc',
    accessToken: 'DI8qtTVDauKAuhXLeFGy4xzfThEahlMcrv0QIS1M1kc',
    host: isPreview ? 'preview.contentful.com' : undefined,
    environment,
  });

export const fetchFromContentful = async <
  T extends contentful.EntrySkeletonType
>({
  queryKey,
}: {
  queryKey: unknown[];
}): Promise<EntryCollection<T>> => {
  const result = await getContentfulClient(
    queryKey[0] as string,
    queryKey[3] as boolean
  ).getEntries<T>(queryKey[2] as PageDataQuery);
  if (result.items.length >= 999) {
    throw new Error('Unable to fetch all contentful');
  }
  return result;
};
