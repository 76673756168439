import styled from 'styled-components';
import { ReactComponent as StarSVG } from './star.svg';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { FC, useState } from 'react';

const STARS_COUNT = 5 as const;

const Container = styled.div`
  display: flex;
  gap: 0.672rem;
`;

const Star = styled(StarSVG)<{ active: boolean }>`
  cursor: pointer;

  &:hover path {
    fill: ${colors.warningDeep};
  }

  ${({ active }) =>
    active &&
    `
      path {
        fill: ${colors.warningDeep};
      }
  `}
`;

type StarSelectionProps = {
  disabled: boolean;
  activeStarsCount: number;
  setActiveStarsCount: (activeStarsCount: number) => void;
};

export const StarSelection: FC<StarSelectionProps> = ({
  disabled,
  activeStarsCount,
  setActiveStarsCount,
}) => {
  const [hoveredStar, setHoveredStar] = useState(0);

  const handleCallback = (func: (num: number) => void, value: number) => {
    if (!disabled) {
      func(value);
    }
  };

  return (
    <Container>
      {Array.from(Array(STARS_COUNT)).map((_, i) => {
        const index = i + 1;

        const active = (() => {
          if (hoveredStar) {
            return hoveredStar >= index;
          }
          return activeStarsCount >= index;
        })();

        return (
          <Star
            key={i}
            active={active}
            onClick={() => handleCallback(setActiveStarsCount, index)}
            onMouseEnter={() => handleCallback(setHoveredStar, index)}
            onMouseLeave={() => handleCallback(setHoveredStar, 0)}
          />
        );
      })}
    </Container>
  );
};
