import React, { FC } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  Text,
  colors,
  getFontSize,
  getFontWeight,
  Size,
  fontWeights,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { ProgressStepperProps } from 'components/ProgressStepper';
import { useExpertProgressData } from '@streaming-advisor/contentful';
import { environment } from 'environments/environment';
import { ResultsPageProps } from 'types/PageProps';
import { RoutePath } from 'RoutePath';
import { usePageContentData } from 'hooks/usePageContentData';
import { ContactExpertButtons } from './ContactExpertButtons';
import { useMediaQuery } from 'hooks/useMediaQuery';

const GettingStartedContainer = styled.aside`
  grid-area: sidebar;
  background-color: ${colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border-top: 1px solid rgb(213, 214, 218);
  justify-content: space-between;

  ${breakpoints.md2} {
    background-color: ${colors.neutralBrightest};
    border: none;
    border-radius: 0;
  }
`;

const Wrapper = styled.div`
  gap: 1.125rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.25rem;

  ${breakpoints.md2} {
    gap: 2rem;
    margin: 3rem;
    margin-bottom: 1.5rem;
    padding-top: 0;
    text-align: left;
  }
`;

const HeaderWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 1.1875rem 0;
  border-radius: 0.5rem;
  background: ${colors.neutralBrightest};

  ${breakpoints.md2} {
    padding: 0;
    border-radius: unset;
    background: unset;
    gap: 1.5rem;
  }
`;

const Title = styled(Text).attrs({ forwardedAs: 'h1' })`
  && {
    display: none;
    font-weight: ${getFontWeight('heavy')};
    font-size: ${getFontSize(5)};

    ${breakpoints.md2} {
      display: block;
    }
  }
`;

const TitleMobile = styled(Text).attrs({ forwardedAs: 'h1' })`
  && {
    font-size: ${getFontSize(4)};
    display: block;
    font-weight: ${fontWeights.heavy};
    margin: 0;

    ${breakpoints.md2} {
      display: none;
    }
  }
`;

const Subtitle = styled(Text).attrs({ forwardedAs: 'h2' })`
  && {
    font-size: ${getFontSize(3)};

    ${breakpoints.md2} {
      font-size: ${getFontSize(4)};
    }
  }
`;

const ShiftSchedule = styled(Text).attrs({ forwardedAs: 'p' })`
  && {
    font-size: ${getFontSize(1)};
    font-weight: ${getFontWeight('feather')};
    color: ${colors.neutralDeeper};
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;

  ${breakpoints.md2} {
    display: none;
  }
`;

const Image = styled.img`
  height: 12.5rem;

  ${breakpoints.md} {
    height: 17.5rem;
  }
`;

export type GettingStartedProps = ProgressStepperProps;

export const GettingStarted: FC<GettingStartedProps> = ({ steps }) => {
  const {
    title,
    subtitle,
    shiftSchedule,
    image: expertTrio,
  } = useExpertProgressData(environment.contentfulEnv, 'expertProgress');

  const { chatButton, callButton } = usePageContentData<ResultsPageProps>(
    RoutePath.Results
  );

  const isDesktopSize = useMediaQuery(`(min-width: ${Size.MD2}px)`);

  return (
    <GettingStartedContainer data-testid="Aside_Container">
      <Wrapper>
        <TitleMobile>{title}</TitleMobile>
        <HeaderWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <ShiftSchedule>{shiftSchedule}</ShiftSchedule>
          <ContactExpertButtons
            chatButton={chatButton}
            callButton={callButton}
          />
          <ImageWrapper>
            <Image src={expertTrio as string} alt="Protech Experts" />
          </ImageWrapper>
        </HeaderWrapper>
      </Wrapper>
      {isDesktopSize && (
        <Image src={expertTrio as string} alt="Protech Experts" />
      )}
    </GettingStartedContainer>
  );
};
