export enum AnalyticEvents {
  VIEW = 'View',
  CLICK = 'Click',
  SUBMIT = 'Submit',
  RESULT = 'Result',
  AE_SESSION_CHANGED = 'aeSdkSessionChanged',
  FEEDBACK = 'Feedback',
}

export enum AnalyticViewTypes {
  PAGE_LOADED = 'Page_Loaded',
  SECTION_LOADED = 'Section_Loaded',
}

export enum AnalyticClickTypes {
  BUTTON_CLICKED = 'Button_Clicked',
  EXPERT_CHAT_CLICKED = 'Expert_Chat_Clicked',
  EXPERT_CALL_CLICKED = 'Expert_Call_Clicked',
}

export enum AnalyticSubmitTypes {
  OPTIONS_SUBMITTED = 'Options_Submitted',
}

export interface WithAnalyticsProps {
  analyticsData?: AnalyticsData;
}

export type AnalyticsData = Record<string, unknown>;
