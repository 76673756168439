// some users are on a different locale like en-AU and en-GB which is not supported by our space
export const validateLanguage = (language: string) => {
  const englishLocale = 'en-US';
  const spanishLocale = 'es-US';
  const validLanguages = [englishLocale, spanishLocale];

  if (!language) return englishLocale;

  if (validLanguages.includes(language)) {
    return language;
  }

  if (language.startsWith('es')) {
    return spanishLocale;
  }

  return englishLocale;
};
