import React, { ReactNode } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import {
  breakpoints,
  fontSizes,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';

interface SpeedTestProgress {
  speed: number;
  status: ReactNode;
}

interface SpeedProgressProps {
  progressData: SpeedTestProgress;
}

const styles = {
  rotation: 1 / 2 + 1 / 5,
  strokeLinecap: 'butt',
  trailColor: '#eee',
};

const SpeedProgressCircle = styled.div`
  width: 17.25rem;
  height: 11.875rem;

  path {
    stroke-width: 4;
  }

  @media all and (max-width: 450px) {
    width: 300px;
  }

  .CircularProgressbar-path {
    stroke: black !important;
  }
`;

const SpeedTestProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 290px;
  margin-bottom: 40px;
`;

const CircleNumbers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #999;
  width: 100%;
  font-size: ${fontSizes[0]};
  margin-top: 0.5rem;
  padding-left: 0.5rem;

  ${breakpoints.md} {
    max-width: 15.5rem;
    width: 100%;
    margin-top: -0.5rem;
  }
`;

const Box = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -3.125rem;
`;

const CurrentSpeedLabel = styled.span`
  font-size: ${fontSizes[7]};
`;

const StatusLabel = styled(Text)`
  &&& {
    font-size: 1rem;
    margin-top: -1rem;
  }
`;

export const SpeedProgress = ({ progressData }: SpeedProgressProps) => {
  const { speed, status } = progressData;
  const SPEED_THRESHOLD = 250;
  const speedValue =
    speed > SPEED_THRESHOLD ? (speed / SPEED_THRESHOLD) * 100 : speed;

  return (
    <SpeedTestProgressContainer>
      <SpeedProgressCircle>
        <CircularProgressbarWithChildren
          value={speedValue} // the actual progress
          circleRatio={0.6}
          styles={buildStyles(styles)}
        >
          <Box>
            <CurrentSpeedLabel>{speed}</CurrentSpeedLabel>
            <Text size={1} weight="feather" color="#999">
              Mbps
            </Text>
          </Box>
        </CircularProgressbarWithChildren>
      </SpeedProgressCircle>
      <CircleNumbers>
        <div>0</div>
        {speed > SPEED_THRESHOLD ? <div>250+</div> : <div>100+</div>}
      </CircleNumbers>
      <StatusLabel size={2} weight="feather">
        {status}
      </StatusLabel>
    </SpeedTestProgressContainer>
  );
};
