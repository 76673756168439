import { useRecoilState } from 'recoil';
import {
  NetworkPageState,
  PageState,
  QuestionPageState,
  RouterPageState,
  pageState,
} from 'states/atoms';

type UsePageStateResponse = {
  page: PageState;
  setNetworkState: (networkPageState: Partial<NetworkPageState>) => void;
  setRouterState: (routerPageState: RouterPageState | null) => void;
  setQuestionState: (questionPageState: QuestionPageState) => void;
  setResultsState: (results: PageState['results']) => void;
};

export const usePageState = (): UsePageStateResponse => {
  const [page, setPage] = useRecoilState(pageState);

  const setNetworkState = (networkPageState: Partial<NetworkPageState>) => {
    setPage((oldPage) => ({
      ...oldPage,
      networkState: {
        ...oldPage.networkState,
        ...networkPageState,
      },
    }));
  };

  const setRouterState = (routerPageState: RouterPageState | null = null) => {
    setPage((oldPage) => ({
      ...oldPage,
      routerState: routerPageState,
    }));
  };

  const setQuestionState = (questionPageState: QuestionPageState) => {
    setPage((oldPage) => ({
      ...oldPage,
      questionState: questionPageState,
    }));
  };

  const setResultsState = (results: PageState['results']) => {
    setPage((oldPage) => ({
      ...oldPage,
      results,
    }));
  };

  return {
    page,
    setNetworkState,
    setRouterState,
    setQuestionState,
    setResultsState,
  };
};
